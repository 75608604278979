<template>
  <div class="invoice_container">
    <div class="header">
      <div class="picker">
        <van-field readonly clickable label="选择开单人" :value="patName" @click="patNamePickerShow = true"
          right-icon="arrow-down" />
        <van-popup v-model="patNamePickerShow" round position="bottom">
          <van-picker show-toolbar :columns="patNameList" @cancel="patNamePickerShow = false" @confirm="patNameConfirm" />
        </van-popup>
      </div>
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">温馨提示：默认查询7日内的可开发票记录</van-notice-bar>
      <div class="picker">
        <van-field readonly clickable label="选择开票日期" :value="date" @click="datePickerShow = true" right-icon="arrow-down"
          class="picker" />
        <van-popup v-model="datePickerShow" round position="bottom">
          <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate"
            :formatter="formatter" @confirm="dateConfirm" @cancel="dateCancel" />
        </van-popup>
      </div>
    </div>
    <div class="list">
      <van-cell v-for="(item, index) in invoiceList" :key="index" style="white-space: pre-line;" :title="item.deptName"
        :value="item.invoiceTime" :label="item.flag === '1' ? '已开发票' : '未开发票'" icon="ecard-pay" is-link
        @click="navDetail(item.agtOrdNum, item.invoiceNum)" />
    </div>
    <van-empty description="暂无内容" v-if="invoiceList === ''" />
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Invoice",
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      medicalCardNo: "",
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      date: "",
      datePickerShow: false,
      end: "",
      invoiceList: []
    };
  },
  created () {
    this.date = this.formatTime(-6);
    this.end = this.formatTime(0);
    this.getMembers();
  },
  methods: {
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name);
        }
        this.getOnlineInvoiceList();
        this.patName = this.patNameList[this.patNameIndex];
      } else {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },
    //获取电子发票列表
    async getOnlineInvoiceList () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/onlineInvoiceList",
        {
          beginTime: this.date.replace(/-/g, "/"),
          endTime: this.end.replace(/-/g, "/"),
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type,
          idCard: this.patNameArray[this.patNameIndex].card_no
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.invoiceList = res.data.item;
      } else {
        this.invoiceList = "";
        this.$toast.fail(res.msg);
      }
    },
    //获取电子发票详情
    async getInvoiceDetail (agtOrdNum, invoiceNum) {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost("/wxapp/outpatient/viewInvoice", {
        agtOrdNum: agtOrdNum,
        invoiceNum: invoiceNum
      });
      this.$toast.clear();
      if (res.code === 0) {
        window.location.href = res.data.invoiceUrl;
      } else {
        this.$toast.fail(res.msg);
      }
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.getOnlineInvoiceList();
    },
    formatter (type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return `${val}日`;
    },
    formatTime (num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let currentShowTime = nowTime + -6 * oneDayTime;
      let myDate = new Date(ShowTime);
      let currentDate = new Date(currentShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      this.currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return y + "-" + m + "-" + d;
    },
    dateFormat (dateData) {
      let date = new Date(dateData);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let time = y + "-" + m + "-" + d;
      let today = new Date().getFullYear();
      return time;
    },
    dateConfirm (value) {
      this.date = this.dateFormat(value);
      this.datePickerShow = false;
      this.getOnlineInvoiceList();
    },
    dateCancel () {
      this.datePickerShow = false;
    },
    navDetail (agtOrdNum, invoiceNum) {
      this.getInvoiceDetail(agtOrdNum, invoiceNum);
    }
  }
};
</script>

<style scope>
.invoice_container {
  padding-bottom: 60px;
}

.invoice_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 2px solid rgb(201, 191, 191);
}

.invoice_container .header .picker .van-cell {
  padding: 30px;
}

.invoice_container .header .picker .van-cell::after {
  border: 0;
}

.invoice_container .header .picker .van-field__label {
  text-align: left;
  font-size: 40px;
}

.invoice_container .header .picker .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.invoice_container .header .picker .van-icon {
  font-size: 40px;
}

.invoice_container .header .picker .van-picker-column {
  font-size: 40px;
}

.invoice_container .header .picker .van-picker__toolbar {
  height: 150px;
}

.invoice_container .header .picker .van-picker__cancel,
.invoice_container .header .picker .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.invoice_container .header .van-notice-bar__content {
  font-size: 32px;
}

.invoice_container .header .van-notice-bar__left-icon {
  font-size: 32px;
}

.invoice_container .list {
  padding-bottom: 80px;
}

.invoice_container .list .van-cell {
  font-size: 40px;
  border-bottom: 1px solid #f5f5f5;
  padding: 30px;
}

.invoice_container .list .van-cell__label {
  font-size: 36px;
  margin-top: 20px;
}

.invoice_container .list .van-cell__value {
  text-align: left;
}

.invoice_container .list .van-icon {
  font-size: 40px;
}

.invoice_container .van-cell__right-icon {
  margin-top: 40px;
}
</style>

